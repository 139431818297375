/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import './preferred-customers.scss';
import useWindowDimensions from '../../services/useWindowDimensions';

const PreferredCustomerAccountChanges = ({ preferredCustomerId, statuses, setPreferredCustomerIdToEdit }) => {
    const [preferredCustomerAccountChanges, setPreferredCustomerAccountChanges] = useState([]);
    const [loading, setLoading] = useState(true);
    const { loadedSizeIsMobile } = useWindowDimensions();
    const paymentColumns = [
        { field: 'changeDate', headerName: 'Change Date', flex: 175, renderCell: function (t) { return t.value.toLocaleString("en-US").replace(':00 ', ' ') } },
        { field: 'oldStatus', headerName: 'Old Status', flex: 100 },
        { field: 'newStatus', headerName: 'New Status', flex: 100 },
        { field: 'changeMadeBy', headerName: 'Change Made By', flex: 100 },
        { field: 'notes', headerName: 'Notes', flex: 200 },
    ];

    useEffect(() => {
        const getPreferredCustomerAccountChanges = async () => {
            apiAdmin.get(`/preferred-customer-account-changes?preferredCustomerId=${preferredCustomerId}`)
                .then((response) => {
                    setPreferredCustomerAccountChanges(response.data.map(p => ({ 
                        id: p.preferredCustomerAccountChangeId, 
                        changeDate: new Date(p.changeDate), 
                        oldStatus: p.oldPreferredCustomerStatusId === -1 ? '' : statuses.find(s => s.preferredCustomerStatusId === p.oldPreferredCustomerStatusId).statusDescription,
                        newStatus: statuses.find(s => s.preferredCustomerStatusId === p.newPreferredCustomerStatusId).statusDescription, 
                        changeMadeBy: p.changeMadeBy,
                        notes: p.notes.replace('CHANGE NOTE: <br />', '').trimEnd().split('<br />').filter(f => f !== '').join(', ')
                    })))
                    setLoading(false)
                });
        }
        getPreferredCustomerAccountChanges();
    }, [preferredCustomerId]);

    return (
        <div>
            <DataGridPro
                rows={preferredCustomerAccountChanges}
                columns={paymentColumns}
                loading={loading}
                slotProps={{
                    loadingOverlay: {
                        variant: 'skeleton',
                        noRowsVariant: 'skeleton',
                    }
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            oldStatus: !loadedSizeIsMobile,
                            newStatus: !loadedSizeIsMobile,
                            changeMadeBy: !loadedSizeIsMobile,
                        }
                    },
                }}
                autoHeight
                getRowHeight={()=> "auto"}
                disableRowSelectionOnClick
                alignItems="center"
            />
            <input type="button" className="btn btn-primary mt-3" value="Back To List" onClick={() => setPreferredCustomerIdToEdit(null)} />
        </div>
    );
}

export default PreferredCustomerAccountChanges;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd, MdContactPage, MdContentCopy, MdOutlinePictureAsPdf, MdLock } from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import { TextField, ToggleButtonGroup, ToggleButton, MenuItem, Paper } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {DateTime} from 'luxon';
import OutlinedDiv from '../layout/OutlinedDiv';
import { Editor } from 'react-draft-wysiwyg';
import './../styles/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import ClassRegistrants from './class-registrations';
import fileDownload from 'js-file-download';
import { FormatHTMLForWYSIWYGEditor, blurOnEnterKey } from '../../services/utilities';
import SpinnerLoader from '../layout/spinner-loader';
import useWindowDimensions from '../../services/useWindowDimensions';
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { useOktaAuth } from '@okta/okta-react';

const Classes = (props) => {
    const { register, handleSubmit, setValue, reset, formState: { errors }, control  } = useForm();
    const { authState } = useOktaAuth();
    const [classes, setClasses] = useState([]);
    const [classIdToEdit, setClassIdToEdit] = useState(null);
    const [classToEdit, setClassToEdit] = useState(null);
    const [classIdToDelete, setClassIdToDelete] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [regions, setRegions] = useState([]);
    const [offices, setOffices] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState('Other');
    const [selectedClassState, setSelectedClassState] = useState('ID');
    const [timeZone, setTimeZone] = useState('Mountain Time');
    const [emailNoteEditorState, setEmailNoteEditorState] = useState(
        () => EditorState.createEmpty(),
    )
    const [classRegistrantIdToDelete, setClassRegistrantIdToDelete] = useState(null);
    const [registrants, setRegistrants] = useState([]);
    const [registrantDeleted, setRegistrantDeleted] = useState(false);
    const [certificates, setCertificates] = useState(null);
    const [classCertificateId, setClassCertificateId] = useState(0);
    const [creatingCertificates, setCreatingCertificates] = useState(false);
    const [expandedRowIds, setExpandedRowIds] = useState([]);
    const [classesListType, setClassesListType] = useState('upcoming');
    const [classesToShow, setClassesToShow] = useState([]);
    const { height, width } = useWindowDimensions();
    const [fileDropzone, setFileDropzone] = useState(null);
    const [relatedDocs, setRelatedDocs] = useState([]);
    const [gettingRelatedDocs, setGettingRelatedDocs] = useState(false);
    const [relatedDocsId, setRelatedDocsId] = useState(0);

    const columns = [
        { field: 'className', headerName: 'Class Name', flex: 600 },
        { field: 'private', headerName: '', flex: 25, renderCell: function (t) { return t.value ? <MdLock/> : <></>} },
        { field: 'location', headerName: 'Location', flex: 150 },
        { field: 'classDate', headerName: 'Class Date', flex: 175, renderCell: function (t) { return t.value.toLocaleString("en-US").replace(':00 ', ' ')} },
        { field: 'registrantCount', headerName: 'How Many', flex: 150 },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 180,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [         
                    <GridActionsCellItem
                        icon={<MdContactPage title="Related Documents"/>}
                        label="SRelated Documents"
                        onClick={() => { getRelatedDocs(id); setRelatedDocsId(id) }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#relatedDocsModal" role="button" 
                    />, 
                    <GridActionsCellItem
                        icon={<MdOutlinePictureAsPdf title="Certificates" />}
                        label="Copy"
                        onClick={() => { getCertificates(id); setClassCertificateId(id) }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#certificateModal" role="button" 
                    />, 
                    <GridActionsCellItem
                        icon={<MdContentCopy title="Copy"/>}
                        label="Copy"
                        onClick={() => { copyClass(id)}}
                        color="inherit"
                    />,                    
                    <GridActionsCellItem
                        icon={<MdEdit title="Edit" />}
                        label="Edit"
                        onClick={() => { setClassIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete title="Delete" />}
                        label="Delete"
                        onClick={() => { setClassIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                ];
            }
        }
    ];

    const getRelatedDocs = id => {
        setGettingRelatedDocs(true);
        if (fileDropzone) fileDropzone.disable();
        setFileDropzone(new Dropzone("div#file", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/upload-related-class-document?classId=" + id,  maxFilesize: 15000000, headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
        const getDocs = async () => {
            apiAdmin.get(`/class-related-documents?classId=${id}`)
                .then((response) => {
                    setRelatedDocs(response.data);
                    setGettingRelatedDocs(false);
                });
        }
        getDocs();
    }

    const downloadSignInSheet = id => {
        const download = async () => {
            apiAdmin.get(`/class-sign-in-sheet?classId=${id}`, { responseType: 'blob' })
                .then((response) => {
                    fileDownload(response.data, 'SignInSheet.pdf');
                });
        }
        download();

    }

    const getCertificates = id => {
        const getCerts = async () => {
            apiAdmin.get(`/class-certificates?classId=${id}`)
                .then((response) => {
                    setCertificates(response.data);
                });
        }
        getCerts();
    }

    const createCertificates = id => {
        if (!id || id === 0) return;
        setCreatingCertificates(true);
        const createCerts = async () => {
            apiAdmin.get(`/create-class-certificates?classId=${id}`)
                .then((response) => {
                    setCreatingCertificates(false);
                    getCertificates(id);
                    setClassCertificateId(0);
                });
        }
        createCerts();

    }

    const deleteRegistrant = () => {
        const deleteFunc = async () => {
            await apiAdmin.post(`/delete-registrant?classRegistrationId=`+ classRegistrantIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setClassRegistrantIdToDelete(null);
                        setRegistrantDeleted(true);
                    } else { 
                        setError("There was an error deleting the registrant. Please try again.")
                    }
                });
        }
        deleteFunc();
    }

    useEffect(() => {
        const getClasses = async () => {
            apiAdmin.get(`/classes`)
                .then((response) => {
                    setClasses(response.data.map((a) => ({ 
                        id: a.classId, 
                        className: a.className, 
                        private: a.isPrivate,
                        classRegistrations: a.ceClassRegistrations, 
                        location: a.locationCity + (a.classState ? ', ' + a.classState : ''), 
                        classDateISO: a.classDate, 
                        registrantCount: a.ceClassRegistrations.filter(c => c.regTypeId === 1).length + ' of ' + a.maxRegistrants, 
                        classDate: DateTime.fromISO(a.classDate).toFormat('MMMM dd, yyyy')})));

                });
        }

        getClasses();

        const getRegions = async () => {
            apiAdmin.get(`/regions`)
                .then((response) => {
                    setRegions(response.data);
                });
        }
        getRegions();

        const getOffices = async () => {
            apiAdmin.get(`/offices`)
                .then((response) => {
                    setOffices(response.data);                    
                });
        }

        getOffices();

    }, [submitted, deleted]);

    useEffect(() => {
        if (classesListType === 'upcoming') {
            setClassesToShow(classes.filter(c => DateTime.fromISO(c.classDateISO) > DateTime.now().minus({ days: 7 })))
        } else { 
            setClassesToShow(classes.filter(c => DateTime.fromISO(c.classDateISO) <= DateTime.now().minus({ days: 7 })).sort((a, b) => DateTime.fromISO(b.classDateISO).diff(DateTime.fromISO(a.classDateISO))))
        }

    }, [classes, classesListType]);

    useEffect(() => {
        if (!classIdToEdit || classIdToEdit === -1) return;
        const getClass = async () => {
            apiAdmin.get(`/class?classId=${classIdToEdit}`)
                .then((response) => {
                    setClassToEdit(response.data);
                    Object.keys(response.data).forEach(function (key, index) {
                        setValue(key, response.data[key]);
                    });    
                    var classDate = DateTime.fromFormat(response.data.classDate.split('T')[0] + ' ' + response.data.classTime, 'yyyy-MM-dd t');
                    var classEndDate = DateTime.fromFormat(response.data.classDateEnd.split('T')[0] + ' ' + response.data.classEndTime, 'yyyy-MM-dd t');
                    setValue('classDate', classDate);
                    setValue('classEndDate', classEndDate);
                    setTimeZone(response.data.timeZone);
                    var locationParts = response.data.location.split('<br />');
                    var location = offices.find(o => o.officeName === locationParts[0]);
                    setSelectedLocation(location ? location.officeName : 'Other');
                    setSelectedRegion(response.data.regionId ?? 'REMOTE');
                    if (!location) { 
                        setValue('otherLocationName', locationParts[0]);
                        if (locationParts.length > 1) {
                            setValue('otherLocationAddress', locationParts[1]);
                            setValue('otherLocationCity', locationParts[2].split(',')[0]);
                            setValue('otherLocationState', locationParts[2].split(', ')[1].split(' ')[0]);
                            setValue('otherLocationZip', locationParts[2].split(', ')[1].split(' ')[1]);
                        }
                    }
                });
        }
        getClass();
    }, [classIdToEdit]);

    useEffect(() => {
        if (classToEdit || classIdToEdit === -1) {
            setSubmitted(false);
            if (classToEdit) {
                setEmailNoteEditorState(FormatHTMLForWYSIWYGEditor(classToEdit.confirmationEmailNote));  
            } else { 
                setEmailNoteEditorState(EditorState.createEmpty());
            }
        }
    }, [classToEdit, classIdToEdit]);

    useEffect(() => {
        if (selectedRegion === 'REMOTE') {
            setValue('otherLocationName', 'This is a remote class. Please see confirmation email for instructions on how to join.')
        } else { 
            setValue('otherLocationName', '')
        }
    }, [selectedRegion]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setClassIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add New Class
                </span>
                <GridToolbarQuickFilter  {...props.quickFilterProps}/>

                <div className="col">
                    <ToggleButtonGroup
                        className="float-end"
                        value={classesListType}
                        exclusive
                        onChange={(e, newType) => {setClassesListType(newType)}}
                        aria-label="Upcoming or Past Toggle"
                        >
                            <ToggleButton value="upcoming" aria-label="Upcoming Classes">Upcoming Classes</ToggleButton>
                            <ToggleButton value="past" aria-label="Past Classes">Past Classes</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        if (selectedRegion === 'REMOTE') {
            data.locationCity = 'REMOTE';
            data.location = data.otherLocationName;
            data.classState = selectedClassState;
            data.isRemote = true;
        } else if (selectedLocation === 'Other') {
            data.location = `${data.otherLocationName}<br />${data.otherLocationAddress}<br />${data.otherLocationCity}, ${data.otherLocationState} ${data.otherLocationZip}<br />`;
            data.locationCity = data.otherLocationCity;
            data.classState = data.otherLocationState;

        } else { 
            var office = offices.find(o => o.officeName === selectedLocation);
            data.location = `${office.officeName}<br />${office.address1}<br />${office.city}, ${office.stateAbbrev} ${office.zip}<br />`;
            data.locationCity = office.city;
            data.classState = office.stateAbbrev;
        }
        const classObject = {
            ...data,
            classId: classIdToEdit,
            classTime: data.classDate.toFormat('t'),
            classEndTime: data.classEndDate.toFormat('t'),
            timeZone: timeZone,
            confirmationEmailNote: draftToHtml(convertToRaw(emailNoteEditorState.getCurrentContent())),
            contEdCredits: data.contEdCredits === '' ? 0 : data.contEdCredits,
            fee: data.fee === '' ? 0 : data.fee,


        }
        const saveClass = async () => {
            await apiAdmin.post(`/save-class`, classObject)
                .then((response) => {
                    if (response.data) {
                        setClassIdToEdit(null);
                        setClassToEdit(null);
                        setSubmitted(true);
                        setSelectedLocation('Other');
                        setSelectedRegion(1);
                        setTimeZone('Mountain Time');
                        reset();
                    } else { 
                        setError("There was an error saving the class. Please try again.")
                    }
                });
        }
        saveClass();
    }

    const copyClass = id => {
        const getClass = async () => {
            apiAdmin.get(`/class?classId=${id}`)
                .then((response) => {
                    const saveClass = async () => {
                        response.data.classId = -1;
                        response.data.className = response.data.className + ' (COPY)'
                        await apiAdmin.post(`/save-class`, response.data)
                            .then((response) => {
                                if (response.data) {
                                    setSubmitted(true);
                                    reset();
                                } else { 
                                    setError("There was an error copying the class. Please try again.")
                                }
                            });
                    }
                    saveClass();
                });
        }
        getClass();

    }

    const deleteClass = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-class?classId=`+ classIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setClassIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the class. Please try again.")
                    }
                });
        }
        deleteMessage();
    }

    function DetailPanelContent({ row: rowProp }) {
        return (
            <Paper className="p-3">
                <ClassRegistrants classes={classes} classId={rowProp.id} registrants={registrants} setRegistrants={setRegistrants} setClassRegistrantIdToDelete={setClassRegistrantIdToDelete} registrantDeleted={registrantDeleted} setRegistrantDeleted={setRegistrantDeleted} />
            </Paper>
        )    
    }

    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,);
      }, []);
    
    return (
        <div>
            <Helmet>
                <title>Classes</title>
            </Helmet>
            <h1>Classes</h1>
            
            {classToEdit || classIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <TextField
                                label="Class Name *"
                                {...register("className", {required: true})}
                                className="w-100"
                            />
                            {errors?.className?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Instructor Name *"
                                {...register("instructorName", {required: true})}
                                className="w-100"
                            />
                            {errors?.instructorName?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Course Approval Number"
                                {...register("courseApprovalNum")}
                                className="w-100"
                            />
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-4 col-6">
                                <Controller
                                    name="classDate"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <DateTimePicker  value={value} onChange={onChange} className="w-100" label="Start Date/Time"/>
                                    )}
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <Controller
                                    name="classEndDate"
                                    control={control}
                                   render={({ field: { onChange, value } }) => (
                                        <DateTimePicker  value={value} onChange={onChange} className="w-100" label="End Date/Time"/>
                                    )}
                                />
                            </div>
                            <div className="col-md-4 col-12 mt-2 mt-md-0">
                                <ToggleButtonGroup
                                    value={timeZone}
                                    exclusive
                                    onChange={(e, newTimeZone) => {setTimeZone(newTimeZone)}}
                                    aria-label="Time Zone"
                                >
                                    <ToggleButton value="Mountain Time" aria-label="Mountain Time">Mountain Time</ToggleButton>
                                    <ToggleButton value="Pacific Time" aria-label="Pacific Time">Pacific Time</ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        select
                                        label="Region *"
                                        value={selectedRegion}
                                        className="w-100"
                                        onChange={(e) => setSelectedRegion(e.target.value)}
                                    >
                                        {regions.map((option) => (
                                            <MenuItem key={"r_" + option.regionId} value={option.regionId}>
                                                {option.regionName}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="REMOTE">Remote</MenuItem>
                                    </TextField>
                                </div>
                                    {selectedRegion === 'REMOTE' && <div className="col">
                                        <TextField
                                            select
                                            label="Class State"
                                            value={selectedClassState}
                                            className="w-100"
                                            onChange={(e) => setSelectedClassState(e.target.value)}
                                        >
                                            {[... new Set(regions.map(r => r.stateAndCounties[0].state))].map((state, index) => (
                                                <MenuItem key={"s_" + index} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>}
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                                select
                                label="Location"
                                value={selectedLocation}
                                className="w-100"
                                onChange={(e) => setSelectedLocation(e.target.value)}
                            >
                                {offices.filter(o => o.regionId === selectedRegion).map((option) => (
                                    <MenuItem key={"r_" + option.officeId} value={option.officeName}>
                                        {option.officeName} ({option.address1}, {option.city}, {option.stateAbbrev} {option.zip})
                                    </MenuItem>
                                ))}
                                <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                        </div>
                        {(selectedLocation === 'Other' || selectedRegion === 'REMOTE') && <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        label="Location Name"
                                        {...register("otherLocationName")}
                                        className="w-100"
                                    />
                                </div>
                            </div>
                            {selectedLocation === 'Other' && selectedRegion !== 'REMOTE' && <><div className="row mt-2">
                                <div className="col">
                                    <TextField
                                        label="Address"
                                        {...register("otherLocationAddress")}
                                        className="w-100"
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-5">
                                    <TextField
                                        label="City"
                                        {...register("otherLocationCity")}
                                        className="w-100"
                                    />
                                </div>                                   
                                <div className="col-3">
                                    <TextField
                                        label="State"
                                        {...register("otherLocationState")}
                                        className="w-100"
                                        inputProps={{ maxLength: 2 }}
                                    />
                                </div>                                
                                <div className="col-4">
                                    <TextField
                                        label="Zip"
                                        {...register("otherLocationZip")}
                                        className="w-100"
                                        inputProps={{ maxLength: 5 }}
                                    />
                                </div>
                            </div></>}
                        </div>}
                        <div className="mb-3">
                            <TextField
                                label="Continuing Ed Credits"
                                {...register("contEdCredits")}
                                className="w-100"
                                type="number"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Fee"
                                {...register("fee")}
                                className="w-100"
                                helperText='If not filled in, "Fee Pending" will be indicated, and registration not allowed'
                                type="number"
                            />
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        label="Discount Code"
                                        {...register("discountCode")}
                                        className="w-100"
                                        helperText='If discount code is not filled in, discount option will not be shown'
                                    />
                                </div>
                                <div className="col">
                                    <TextField
                                        label="Discounted Fee"
                                        {...register("discountedFee")}
                                        className="w-100"
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Description"
                                {...register("description")}
                                className="w-100"
                                multiline
                                rows={5}
                            />
                        </div>
                        <div className="mb-3">
                            <OutlinedDiv label="Confirmation Email Note">
                                <Editor
                                    editorState={emailNoteEditorState}
                                    editorClassName="wysiwyg-title"
                                    onEditorStateChange={(e) => setEmailNoteEditorState(e)}
                                    />
                            </OutlinedDiv>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Instructor and Proctor Emails"
                                {...register("notificationEmail")}
                                className="w-100"
                                helperText='These email addresses receive an email notification when someone registers. Separate by semi-colons (;).'
                            />
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="requireRealEstateLicenseNum" {...register("requireRealEstateLicenseNum")}  />
                                <label className="form-check-label" htmlFor="requireRealEstateLicenseNum">Require RE License Number</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="requirePayment" {...register("requirePayment")}  />
                                <label className="form-check-label" htmlFor="requirePayment">Require Credit Card Payment</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="isPrivate" {...register("isPrivate")}  />
                                <label className="form-check-label" htmlFor="isPrivate">Private Class</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Max Registrants"
                                {...register("maxRegistrants")}
                                className="w-100"
                                type="number"
                            />
                        </div>

                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setClassIdToEdit(null); setClassToEdit(null); setSubmitted(false); setError(null); setSelectedLocation('Other'); setSelectedRegion(1); setTimeZone('Mountain Time');reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Class saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Class deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <div>
                    <DataGridPro
                        rows={classesToShow}
                        columns={columns}
                        loading={classesToShow.length === 0}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                            toolbar: {
                                quickFilterProps: {
                                    onKeyDown: blurOnEnterKey
                                }
                            }
                        }} 
                        autoHeight
                        rowHeight={38}
                        disableRowSelectionOnClick
                        alignItems="center"
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        initialState={{
                            pagination: {
                              paginationModel: { pageSize: 25, page: 0 },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    registrantCount: width > 1000 ,
                                    location: width > 750 ,
                                    private: width > 500
                                }
                            },
                          }}
                        pagination
                        getDetailPanelContent={DetailPanelContent}
                        getDetailPanelHeight={({ row }) => 'auto'}
                        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                        detailPanelExpandedRowIds={expandedRowIds}

                    />
                </div>
                
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Class?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this class?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteClass()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal shown" tabIndex="-1" id="deleteRegistrantModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Registrant?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this registrant?</p>
                            {registrants.find(r => r.registrationId === classRegistrantIdToDelete)?.regTypeId === 1 && <p>The top waitlisted registrant will be added to the active class roster.</p>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteRegistrant()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal shown" tabIndex="-1" id="certificateModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Class Certificates</h5>
                        </div>
                        <div className="modal-body">
                            {(!certificates || creatingCertificates) && <SpinnerLoader/>}
                            {!creatingCertificates && certificates && certificates.length > 0 &&
                                <div>
                                    <p>These certificates have been created and emailed to the registrants. Click on one below to download a copy.</p>
                                    <ul>
                                        {certificates.map((cert, index) =>
                                            <li key={"cert_" + index}><a href={process.env.REACT_APP_FILES_BASE_URL.replace('public', cert)} target="_blank" rel="noreferrer">{cert.split('/')[cert.split('/').length - 1]}</a></li>
                                        )}
                                    </ul>
                                </div>}
                            {!creatingCertificates && certificates && certificates.length === 0 && <div>
                                    <p>Click the button below to create the certificates and send them to all active registrants.</p>
                                    <button type="button" className="btn btn-primary" onClick={() => createCertificates(classCertificateId)}>Create Certificates</button>

                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {setCertificates(null); setClassCertificateId(0);}}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal shown" tabIndex="-1" id="relatedDocsModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Related Documents</h5>
                        </div>
                        <div className="modal-body">
                            {gettingRelatedDocs && <SpinnerLoader/>}
                            <div className={gettingRelatedDocs ? "d-none" : ""}>
                                {!gettingRelatedDocs && relatedDocs.length > 0 &&
                                    <div>
                                        <ul>
                                            {relatedDocs.map((doc, index) =>
                                                <li key={"doc_" + index}><a href={process.env.REACT_APP_FILES_BASE_URL.replace('public', doc)} target="_blank" rel="noreferrer">{doc.split('/')[doc.split('/').length - 1]}</a></li>
                                            )}
                                        </ul>
                                    </div>
                                }
                                <OutlinedDiv label="Upload Related Docs">
                                    <div id="file" className="dropzone dropzone-previews border-0">
                                        <div className="dz-message" data-dz-message><span>{relatedDocs.length > 0 ? 'Files with the same name will be overwritten.' : 'Completed sign in sheet, evals, etc.'}</span></div>
                                    </div>
                                </OutlinedDiv>
                                <button type="button" className="btn btn-primary mt-3" onClick={() => downloadSignInSheet(relatedDocsId)}>Download Blank Sign in Sheet</button>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { if (fileDropzone) {fileDropzone.destroy(); } setFileDropzone(null); setRelatedDocs([]); setRelatedDocsId(0)}}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Classes;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataGridPro, useGridApiContext, gridRowTreeSelector, GridToolbarContainer, gridFilteredSortedRowIdsSelector, GridActionsCellItem } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd} from 'react-icons/md';
import { useForm } from "react-hook-form";
import { TextField,  MenuItem } from '@mui/material';
import {DateTime} from 'luxon';
import OutlinedDiv from '../layout/OutlinedDiv';


const ClassRegistrants = ({ classes, classId, setClassRegistrantIdToDelete, registrants, setRegistrants, registrantDeleted, setRegistrantDeleted}) => {
    const { register, handleSubmit, setValue, reset, formState: { errors }  } = useForm();
    const [classRegistrantIdToEdit, setClassRegistrantIdToEdit] = useState(null);
    const [classRegistrantToEdit, setClassRegistrantToEdit] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [regTypeId, setRegTypeId] = useState(1);
    const [selectedClass, setSelectedClass] = useState(classId);
    const [loadingRegistrants, setLoadingRegistrants] = useState(false);

    const columns = [
        { field: 'company', headerName: 'Company', export: true, flex: 250 },
        { field: 'address', headerName: 'Address', export: true, flex: 250 },
        { field: 'city', headerName: 'City', flex: 250 },
        { field: 'state', headerName: 'State', flex: 250 },
        { field: 'zip', headerName: 'Zip', flex: 250 },
        { field: 'phone', headerName: 'Phone', flex: 250 },
        { field: 'email', headerName: 'Email', flex: 250 },
        { field: 'licenseNumber', headerName: 'License Number', flex: 250 },
        { field: 'registrationDate', headerName: 'Registration Date', flex: 250 },
        { field: 'paid', headerName: 'Paid', flex: 250 },
        { field: 'notes', headerName: 'Notes', flex: 250 },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 75,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                if (!parseInt(id)) return [];
                return [                                            
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setClassRegistrantIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setClassRegistrantIdToDelete(id); setRegistrantDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteRegistrantModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getRegistrants = async () => {
            setLoadingRegistrants(true);
            apiAdmin.get(`/class-registrants?classId=`+ classId)
                .then((response) => {
                    setRegistrants(response.data.map(r => ({ 
                    id: r.registrationId, 
                    regTypeId: r.regTypeId,
                    company: r.company, 
                    address: r.address, 
                    city: r.city,
                    state: r.state, 
                    zip: r.zip,
                    phone: r.phone, 
                    email: r.email, 
                    licenseNumber: r.realEstateLicenseNum, 
                    registrationDate: r.registrationTimeStamp,
                    registrantName: getRegType(r.regTypeId) + '/' + r.firstName + ' ' + r.lastName, 
                    paid: r.paid, 
                    notes: r.notes })).sort((a, b) => a.regTypeId - b.regTypeId))
                    setLoadingRegistrants(false);
                });
        }

        getRegistrants();
    }, [submitted, registrantDeleted]);

    function getRegType(regTypeId) { 
        switch (regTypeId) { 
            case 1:
                return "Active";
            case 2:
                return "Waitlist";
            case 3:
                return "Dropout";
            default:
                return '';
        }
    }

    useEffect(() => {
        if (!classRegistrantIdToEdit || classRegistrantIdToEdit === -1) return;
        const getClassRegistrant = async () => {
            apiAdmin.get(`/class-registrant?classRegistrationId=${classRegistrantIdToEdit}`)
                .then((response) => {
                    setClassRegistrantToEdit(response.data);
                    setSelectedClass(response.data.classId);
                    setSubmitted(false);
                    setRegistrantDeleted(false);
                    Object.keys(response.data).forEach(function (key, index) {
                        setValue(key, response.data[key]);
                    });
                    setRegTypeId(response.data.regTypeId)

                });
        }
        getClassRegistrant();
    }, [classRegistrantIdToEdit]);

    const getRowsWithoutGroups = ({ apiRef }) => {
        const rows = gridFilteredSortedRowIdsSelector(apiRef);
        const tree = gridRowTreeSelector(apiRef);

        return rows.filter((rowId) => tree[rowId].type !== 'group');
    };

    function EditToolbar(props) {
        const apiRef = useGridApiContext();
        const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setClassRegistrantIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add New Registrant
                </span>
                <div className="col">
                    <button type="button" className="btn btn-primary float-end" onClick={() => handleExport({ getRowsToExport: getRowsWithoutGroups, allColumns: true, includeHeaders: false })}>Export</button>
                </div>
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {

        const registrantObject = {
            ...data,
            registrationId: classRegistrantIdToEdit,
            regTypeId: regTypeId,
            classId: selectedClass
        }
        const saveRegistrant = async () => {
            await apiAdmin.post(`/save-registrant`, registrantObject)
                .then((response) => {
                    if (response.data) {
                        setClassRegistrantIdToEdit(null);
                        setClassRegistrantToEdit(null);
                        setSubmitted(true);
                        setRegTypeId(1);
                        setSelectedClass(classId)
                        reset();
                    } else { 
                        setError("There was an error saving the registrant. Please try again.")
                    }
                });
        }
        saveRegistrant();
    }


    
    return (
        <div>
          
            {classRegistrantToEdit || classRegistrantIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <h3>{classRegistrantIdToEdit === -1 ? 'Add' : 'Edit'} Registrant</h3>
                        <hr/>
                        {classRegistrantToEdit && <div className="mb-3">
                            <OutlinedDiv label="Registration Date">
                                {DateTime.fromISO(classRegistrantToEdit.registrationTimeStamp).toFormat('MM-dd-yyyy')}
                            </OutlinedDiv>
                        </div>}
                        <div className="mb-3">
                            <TextField
                                select
                                label="Class"
                                value={selectedClass}
                                className="w-100"
                                onChange={(e) => setSelectedClass(e.target.value)}
                            >
                                {classes && classes.map(c => { 
                                    return <MenuItem key={"c_" + c.id} value={c.id}>
                                        {c.className} ({DateTime.fromISO(c.classDateISO).toFormat('MM-dd-yyyy')})
                                    </MenuItem>
                                
                                })}
                            </TextField>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="First Name *"
                                {...register("firstName", {required: true})}
                                className="w-100"
                            />
                            {errors?.firstName?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Last Name *"
                                {...register("lastName", {required: true})}
                                className="w-100"
                            />
                            {errors?.lastName?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Address *"
                                {...register("address", {required: true})}
                                className="w-100"
                            />
                            {errors?.address?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Company"
                                {...register("company")}
                                className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <div className="row mt-2">
                                <div className="col-5">
                                    <TextField
                                        label="City"
                                        {...register("city", {required: true})}
                                        className="w-100"
                                    />
                                    {errors?.city?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>                                   
                                <div className="col-3">
                                    <TextField
                                        label="State"
                                        {...register("state", {required: true})}
                                        className="w-100"
                                        inputProps={{ maxLength: 2 }}
                                    />
                                    {errors?.state?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>                                
                                <div className="col-4">
                                    <TextField
                                        label="Zip"
                                        {...register("zip", {required: true})}
                                        className="w-100"
                                        inputProps={{ maxLength: 5 }}
                                    />
                                    {errors?.zip?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Phone *"
                                {...register("phone", {required: true})}
                              className="w-100"
                            />
                            {errors?.phone?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Email *"
                                {...register("email", {required: true})}
                              className="w-100"
                            />
                            {errors?.email?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Paid"
                                {...register("paid")}
                                className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                                select
                                label="Registration Status"
                                value={regTypeId}
                                className="w-100"
                                onChange={(e) => setRegTypeId(e.target.value)}
                            >
                                <MenuItem value={1}>
                                    Active
                                </MenuItem>
                                <MenuItem value={2}>
                                    Waitlist
                                </MenuItem>
                                <MenuItem value={3}>
                                    Dropout
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="License Number"
                                {...register("realEstateLicenseNumber")}
                                className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Notes"
                                {...register("notes")}
                                className="w-100"
                                multiline
                            />
                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary me-4">Submit</button>
                            <button type="button" className="btn btn-primary" onClick={() => { setClassRegistrantIdToEdit(null); setClassRegistrantToEdit(null); setSubmitted(false); setRegistrantDeleted(false); setError(null); setRegTypeId(1); setSelectedClass(classId); reset(); } }>Cancel</button>
                        </div>
                    </div>

                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Registrant saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {registrantDeleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Registrant deleted successfully!<button type="button" className="btn-close" onClick={() => setRegistrantDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}

                    {<DataGridPro
                        rows={registrants}
                        columns={columns}
                        loading={loadingRegistrants}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                        }} 
                        autoHeight
                        rowHeight={38}
                        disableRowSelectionOnClick
                        alignItems="center"
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    company: false,
                                    address: false,
                                    city: false,
                                    state: false,
                                    zip: false,
                                    phone: false,
                                    email: false,
                                    licenseNumber: false,
                                    registrationDate: false,
                                }
                            },
                        }}
                        pagination
                        treeData
                        getTreeDataPath={(row) => { return row.registrantName.split('/'); }}
                        groupingColDef={{
                            minWidth: 300,
                            headerName: "Status"
                        }}
                    />}
            </>
            }


        </div>
    );
}

export default ClassRegistrants;

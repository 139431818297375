/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit } from 'react-icons/md';
import { FilePath, FormatMoney } from '../../services/utilities';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import PromoItemRow from './promo-item-row';
import SpinnerLoader from '../layout/spinner-loader';

const PromoOrderManagement = () => {
    const [promoOrders, setPromoOrders] = useState([]);
    const [orderIdToEdit, setOrderIdToEdit] = useState(null);
    const [orderToEdit, setOrderToEdit] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(DateTime.now().startOf('month'));
    const [endDate, setEndDate] = useState(DateTime.now().endOf('month'));
    const [filteredRows, setFilteredRows] = useState([]);
    const [promoItems, setPromoItems] = useState([]);
    const [cart, setCart] = useState([]);
    const [previewItem, setPreviewItem] = useState(null);
    const [settings, setSettings] = useState(null);
    const [promoOrderItems, setPromoOrderItems] = useState([]);
    const [selectedOrderIds, setSelectedOrderIds] = useState([]);

    const columns = [
        { field: 'orderDate', headerName: 'Date Placed', flex: 100, type: 'date', valueGetter: function (t) { return DateTime.fromISO(t).toJSDate() } },
        { field: 'id', headerName: 'Order #', flex: 50 },
        { field: 'zoneName', headerName: 'Zone', flex: 100 },
        { field: 'orderContact', headerName: 'Order Contact', flex: 100 },
        { field: 'totalAmount', headerName: 'Total', flex: 150, type: 'number', valueFormatter: (val) => { return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); } },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => [
                <GridActionsCellItem
                    icon={<MdEdit />}
                    label="Edit"
                    className="textPrimary"
                    onClick={() => { setOrderIdToEdit(id) }}
                    color="inherit"
                />,
            ],
        },
        {
            field: 'checkbox', headerName: '', width: 50, renderCell: (params) => (
                <input
                    type="checkbox"
                    checked={selectedOrderIds.includes(params.row.id)}
                    onChange={() => handleCheckboxChange(params.row.id)}
                />
            )
        },
    ];

    useEffect(() => {
        const getOrders = async () => {
            apiAdmin.get(`/get-all-promo-orders`)
                .then((response) => {
                    const orders = response.data.map((a) => ({
                        id: a.promoOrderId,
                        orderDate: a.orderDate,
                        zoneName: a.zoneName,
                        orderContact: a.teamMemberName,
                        totalAmount: a.totalAmount,
                    }));
                    setPromoOrders(orders);
                    setFilteredRows(orders); 
                });
        }
        getOrders();

        const getSettings = async () => {
            apiAdmin.get(`/promo-order-settings`)
                .then((response) => {
                    setSettings(response.data);
                });
        }
        getSettings();

    }, [submitted]);

    useEffect(() => {
        if (!orderIdToEdit) return;
       
        const getOrderDetails = async () => {
            apiAdmin.get(`/get-promo-order-details?promoOrderId=${orderIdToEdit}`)
                .then((response) => {
                    setOrderToEdit(response.data[0]);
                    const zoneId = response.data[0].promoOrderZoneId;
                    setPromoOrderItems(response.data[0].promoOrderItems);
                    getPromoItems(zoneId);
                });
        }
        const getPromoItems = async (zoneId) => {
            apiAdmin.get(`/get-promo-orderform-items?promoOrderZoneId=` + zoneId)
                .then((response) => {
                    setPromoItems(response.data.filter(p => !p.discontinued));
                });
        }

        getOrderDetails();

    }, [orderIdToEdit]);
    
    useEffect(() => {
        if (!orderIdToEdit) return;

        const getOrderDetails = async () => {
            apiAdmin.get(`/get-promo-order-details?promoOrderId=${orderIdToEdit}`)
                .then((response) => {
                    setOrderToEdit(response.data[0]);
                    const zoneId = response.data[0].promoOrderZoneId;
                    setPromoOrderItems(response.data[0].promoOrderItems);
                    getPromoItems(zoneId);
                });
        }

        const getPromoItems = async (zoneId) => {
            apiAdmin.get(`/get-promo-orderform-items?promoOrderZoneId=` + zoneId)
                .then((response) => {
                    setPromoItems(response.data.filter(p => !p.discontinued));
                });
        }

        getOrderDetails();

    }, [orderIdToEdit]);
   
    useEffect(() => {
        const loadCartWithPromoOrderItems = async () => {
            if (!promoItems.length || !promoOrderItems.length) return;

            const loadCart = promoOrderItems.map(orderItem => {
                const promoItem = promoItems.find(item => item.promoItemId === orderItem.promoItemId);
                return { promoItem, quantity: orderItem.quantity };
            });

            setCart(loadCart);
        };

        loadCartWithPromoOrderItems();

    }, [promoItems, promoOrderItems]);

    const addToCart = (item, quantity) => {
        var newCart = [...cart];

        if (newCart.map(m => m.promoItem.promoItemId).includes(item.promoItemId)) {
            var foundItem = newCart.find(m => m.promoItem.promoItemId === item.promoItemId);
            foundItem.quantity = quantity;
            if (quantity === '0' || quantity === '') newCart = newCart.filter(c => c.promoItem.promoItemId !== item.promoItemId)
        } else {
            newCart.push({ promoItem: item, quantity: quantity })
        }
        setCart(newCart);
    }

    const handleCheckboxChange = (id) => {
        setSelectedOrderIds(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(orderId => orderId !== id)
                : [...prevSelected, id]
        );
    };

    const handleOrderMerge = async () => {
        if (selectedOrderIds.length !== 2) {
            setError("Please select exactly two orders to merge.");
            return; 
        }

        const selectedZones = selectedOrderIds.map(id => {
            const order = promoOrders.find(order => order.id === id);
            return order.zoneName;
        });

        const allSameZone = selectedZones.every(zone => zone === selectedZones[0]);

        if (!allSameZone) {
            setError("All selected orders must be from the same zone.");
            return; 
        }

        const mergePromoOrders = async () => {
            await apiAdmin.post(`/merge-promo-orders`, selectedOrderIds)
                .then((response) => {
                    if (response.data) {
                        setSubmitted(true);
                        setSelectedOrderIds([]);
                        setError(null); 
                    } else {
                        setError("There was an error merging the promo orders. Please try again.")
                    }
                });
        }
        mergePromoOrders();
    };

    const downloadOrderReport = (startDate, endDate) => {
        const formattedStartDate = DateTime.fromISO(startDate).toISODate();
        const formattedEndDate = DateTime.fromISO(endDate).toISODate();
        window.open(`http://dpvtosql01/ReportServer/Pages/ReportViewer.aspx?%2fTitleOneWeb%2fGetPromoOrders&startDate=${formattedStartDate}&endDate=${formattedEndDate}`, '_blank', 'noreferrer');
    };

    function SearchToolbar(props) {
        return (
            <GridToolbarContainer className="d-flex justify-content-between align-items-end">
                <div className="d-flex align-items-end pt-2">
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                    />
                    <button type="button" className="btn btn-primary align-self-center mx-2" onClick={handleSearch}>Search Date Range</button>
                    <button type="button" className="btn btn-secondary align-self-center mx-2" onClick={handleOrderMerge}>Merge Selected Orders</button>
                    <button type="button" className="btn btn-primary align-self-center mx-2" onClick={() => downloadOrderReport(startDate, endDate)}>Download Report</button>
                </div>
                <GridToolbarQuickFilter className="ml-auto" />
            </GridToolbarContainer>
        );
    }

    const submitOrder = () => {
       
        const promoOrderObject = {
            promoOrderId: orderToEdit.promoOrderId,
            promoOrderZoneId: orderToEdit.promoOrderZoneId,
            notes: orderToEdit.notes,
            orderContactTeamMemberId: orderToEdit.orderContact.contactMemberID,
            promoOrderItems: cart.map(c => ({ promoItemId: c.promoItem.promoItemId, quantity: c.quantity }))
        }
       
        const placePromoOrder = async () => {
            await apiAdmin.post(`/place-promo-order`, promoOrderObject)
                .then((response) => {
                    if (response.data) {
                        setCart([]);
                        setOrderToEdit(null);
                        setOrderIdToEdit(null);
                        setSubmitted(true);
                    } else {
                        setError("There was an error placing the promo order. Please try again.")
                    }

                });
        }
        placePromoOrder();
    }

    const handleSearch = () => {
        const newFilteredRows = promoOrders.filter((row) => {
            const rowDate = DateTime.fromISO(row.orderDate);
            return (
                (!startDate || rowDate.startOf('day') >= startDate.startOf('day')) &&
                (!endDate || rowDate.startOf('day') <= endDate.startOf('day'))
            );
        });
        setFilteredRows(newFilteredRows);
    };
    
    return (
        <div>
            <Helmet>
                <title>Promo Order Management</title>
            </Helmet>
            <h1>Promo Order Management</h1>
           
            {(orderIdToEdit && orderToEdit) ? (
                <>
                    <div className="row">
                        <div className="col-6">
                            <strong>Order ID:</strong> {orderToEdit.promoOrderId}<br />
                            <strong>Date Placed:</strong> {orderToEdit.orderDate}<br />
                            <strong>Order Zone:</strong> {orderToEdit.zoneName}<br />
                            <strong>Notes/Item Priority:</strong> {orderToEdit.notes}
                        </div>
                        <div className="col-6">
                            <strong>{orderToEdit.orderContact.fullName}</strong> <br />
                            {orderToEdit.office.address1}<br />
                            {orderToEdit.office.city}, {orderToEdit.office.stateAbbrev} {orderToEdit.office.zip}
                        </div>
                    </div>
                 
                    {promoItems && promoItems.length > 0 ? <>
                        <div className="row mt-3">
                            <div className="col-2 d-none d-md-flex">
                                <strong>Quantity</strong>
                            </div>
                            <div className="col-2 d-flex d-md-none">
                                <strong>#</strong>
                            </div>
                            <div className="col-4"><strong>Item Name</strong></div>
                            <div className="col-2"><strong>Image</strong></div>
                            <div className="col-2 d-none d-md-flex">
                                <strong>Price With S&H</strong>
                            </div>
                            <div className="col-2 d-flex d-md-none">
                                <strong>Price</strong>
                            </div>
                            <div className="col-2"><strong>Total</strong></div>
                        </div>
                        {promoItems && settings && promoItems.map((pi) => {
                            return <PromoItemRow promoItem={pi} key={"row_" + pi.promoItemId} addToCart={addToCart} setPreviewItem={setPreviewItem} settings={settings} cart={cart} promoOrderItems={orderToEdit.promoOrderItems} />
                        })}
                        <div className="row border-top">
                            <div className="col-2 float-end">
                                <strong>{cart && cart.map(c => parseInt(c.quantity)).reduce((a, b) => a + b, 0)}</strong>
                            </div>
                            <div className="col-4"><strong>Total Items</strong></div>
                            <div className="col-2 d-none d-md-flex">
                                <strong>Total Cost For This Order:</strong>
                            </div>
                            <div className="col-4 d-flex d-md-none">
                                <strong>Total Cost:</strong>
                            </div>
                            <div className="col-2 offset-md-2"><strong>{FormatMoney(cart && cart.map(c => ((Math.round(c.promoItem.price * settings.shippingAndHandlingRate * 100) / 100) * c.quantity)).reduce((a, b) => a + b, 0))}</strong></div>
                        </div>
                        <div className="row">
                            <div className="col-6 offset-6 col-md-4 offset-md-8 py-2 ">
                                <button type="submit" disabled={cart.length === 0} className="btn btn-primary me-4" onClick={() => submitOrder()}>Submit</button>
                                <button type="button" className="btn btn-primary" onClick={() => { setOrderIdToEdit(null); setOrderToEdit(null); setSubmitted(false); setError(null); setCart([]) }}>Cancel</button>
                            </div>
                        </div>
                    </> : <SpinnerLoader />}
                  
                </>
            ) : (
                <>
                    {submitted && <div className="alert alert-success alert-dismissible" role="alert">Order updated successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                   
                        <DataGridPro
                            rows={filteredRows}
                            columns={columns}
                            loading={promoOrders.length === 0}
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'skeleton',
                                    noRowsVariant: 'skeleton',
                                },
                            }}
                            autoHeight
                            rowHeight={38}
                            disableRowSelectionOnClick
                            alignItems="center"
                            slots={{
                                toolbar: SearchToolbar,
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 25, page: 0 },
                                },
                            }}
                            pagination
                        />
                   
                </>
            )}
            <div className="modal shown" tabIndex="-1" id="previewModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{previewItem && previewItem.itemName}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setPreviewItem(null)}></button>
                        </div>
                        <div className="modal-body">
                            {previewItem && <img src={FilePath('/promoitemimages/' + previewItem.photo)} alt={previewItem.itemName} height="auto" width="100%" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PromoOrderManagement;

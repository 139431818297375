/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import {  FilePath, FormatPhoneInput, blurOnEnterKey } from '../../services/utilities';
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import { TextField, MenuItem, Select, ListSubheader, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTime} from 'luxon';
import OutlinedDiv from '../layout/OutlinedDiv';
import { useOktaAuth } from '@okta/okta-react';
import useWindowDimensions from '../../services/useWindowDimensions';

const TeamMembers = (props) => {
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control  } = useForm();
    const { authState } = useOktaAuth();
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMemberIdToEdit, setTeamMemberIdToEdit] = useState(null);
    const [teamMemberToEdit, setTeamMemberToEdit] = useState(null);
    const [teamMemberIdToDelete, setTeamMemberIdToDelete] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [error, setError] = useState(null);
    const [imageDropzone, setImageDropzone] = useState(null);
    const [regions, setRegions] = useState([]);
    const [primaryOffice, setPrimaryOffice] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedOffices, setSelectedOffices] = useState([]);
    const [selectedEOs, setSelectedEOs] = useState([]);
    const [offices, setOffices] = useState([]);
    const [departmentTeam, setDepartmentTeam] = useState([]);
    const [officeTeam, setOfficeTeam] = useState([]);
    const [phoneInputValue, setPhoneInputValue] = useState("");    
    const { loadedSizeIsMobile, width } = useWindowDimensions();

    const departments = ['Escrow', 'Admin & Other', 'Sales', 'Marketing', 'Title', 'Leadership']

    const handleOfficeChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedOffices(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleEOChange = (event) => {
        const {
            target: { value },
        } = event;

        setSelectedEOs(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handlePhoneInput = (e) => {
        const formattedPhoneNumber = FormatPhoneInput(e.target.value);
        setPhoneInputValue(formattedPhoneNumber);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const columns = [
        { field: 'firstName', headerName: loadedSizeIsMobile ? 'First' : 'First Name', flex: 200, hideSortIcons: loadedSizeIsMobile },
        { field: 'lastName', headerName: loadedSizeIsMobile ? 'Last' : 'Last Name', flex: 200, hideSortIcons: loadedSizeIsMobile },

        { field: 'department', headerName: loadedSizeIsMobile ? 'Dept' : 'Department', flex: 200, hideSortIcons: loadedSizeIsMobile },
        { field: 'offices', headerName: 'Office(s)', flex: 200, hideSortIcons: loadedSizeIsMobile },
        {
            field: 'actions',
            type: 'actions',
            renderHeader: function (t) { return loadedSizeIsMobile ? <MdEdit/> : <span>Actions</span >},
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        onClick={() => { setTeamMemberIdToEdit(id) }}
                        color="inherit"
                        showInMenu={loadedSizeIsMobile}
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setTeamMemberIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button"
                        showInMenu={loadedSizeIsMobile}
                    />,
                ];
            }
        }
    ];

    const teamColumns = [
        { field: 'firstName', headerName: 'First Name', flex: 200 },
        { field: 'lastName', headerName: 'Last Name', flex: 200 },
    ];


    useEffect(() => {
        const getTeamMembers = async () => {
            apiAdmin.get(`/team-members`)
                .then((response) => {
                    setTeamMembers(response.data.map((a) => ({ id: a.teamMemberId, firstName: a.firstName, lastName: a.lastName, department: a.department, title: a.title, offices: a.officePositions.map(m => m.office.officeName).join(', ') })));

                });
        }

        getTeamMembers();


        const getRegionsWithOffices = async () => {
            apiAdmin.get(`/regions-with-offices`)
                .then((response) => {
                    setRegions(response.data);
                    
                });

        }

        getRegionsWithOffices();

        const getOffices = async () => {
            apiAdmin.get(`/offices`)
                .then((response) => {
                    setOffices(response.data);                    
                });
        }

        getOffices();


    }, [submitted, deleted]);



    useEffect(() => {
        if (!teamMemberIdToEdit || teamMemberIdToEdit === -1) return;
        const getTeamMember = async () => {
            apiAdmin.get(`/team-member?teamMemberId=${teamMemberIdToEdit}`)
                .then((response) => {
                    setImageDropzone(null);
                    setTeamMemberToEdit(response.data);
                    setValue('firstName', response.data.firstName);
                    setValue('lastName', response.data.lastName);
                    setValue('nameTitle', response.data.nameTitle);
                    setValue('title', response.data.title);
                    setValue('email', response.data.email);
                    setPhoneInputValue(response.data.directLine);
                    setValue('text', response.data.text);
                    setValue('useHeadshotInSignature', response.data.useHeadshotInSignature);
                    setValue('anniversaryDate', DateTime.fromISO(response.data.anniversaryDate));
                    setValue('birthday', DateTime.fromISO(response.data.birthday));
                    setValue('listed', response.data.listed);
                    setPrimaryOffice(response.data.primaryOfficeId)
                    setSelectedDepartment(response.data.department);
                    setSelectedEOs(response.data.escrowOfficers.map(eo => eo.escrowOfficerId));
                    setSelectedOffices(offices.filter(t => response.data.officePositions.map(t => t.officeId).includes(t.officeId)).map(f => f.officeName))
                });
        }
        getTeamMember();
    }, [teamMemberIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer className="row">
                <div className="col">
                    <span className="btn-link btn px-0 px-md-2" onClick={() => {setTeamMemberIdToEdit(-1)} }>
                        <MdAdd className="mb-1"/> Add Team Member
                    </span>
                </div>
                <div className="col">
                    <GridToolbarQuickFilter  {...props.quickFilterProps} className="float-end"/>
                </div>
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        var officeIds = offices.filter(o => selectedOffices.includes(o.officeName)).map(o => o.officeId)
        const teamMemberObject = {
            ...data,
            teamMemberId: teamMemberIdToEdit,
            image: imageDropzone.getAcceptedFiles().length > 0 ? imageDropzone.getAcceptedFiles()[0].name : '',
            officeIds: officeIds, 
            escrowOfficerIds: selectedEOs,
            department: selectedDepartment,
            primaryOfficeId: officeIds.length === 1 || !primaryOffice ? officeIds[0] : primaryOffice
        }
        const saveTeamMember = async () => {
            await apiAdmin.post(`/save-team-member`, teamMemberObject)
                .then((response) => {
                    if (response.data) {
                        setTeamMemberIdToEdit(null);
                        setTeamMemberToEdit(null);
                        setSubmitted(true);
                        reset();
                        setSelectedDepartment(null);
                        setSelectedOffices([]);
                        setOfficeTeam([]);
                        setSelectedEOs([])
                        setPrimaryOffice(null);
                        setPhoneInputValue('')
                    } else { 
                        setError("There was an error saving the team member. Please try again.")
                    }
                });
        }
        saveTeamMember();
    }

    const deleteTeamMember = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-team-member?teamMemberId=`+ teamMemberIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setTeamMemberIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the team member. Please try again.")
                    }
                });
        }
        deleteMessage();
    }

    
    useEffect(() => {
        if (teamMemberToEdit || teamMemberIdToEdit === -1) {
            setSubmitted(false);
            if (imageDropzone) imageDropzone.disable();
            setImageDropzone(new Dropzone("div#image", { url: process.env.REACT_APP_API_ADMIN_BASE_URL + "/team-member/image", createImageThumbnails: true, maxFilesize: 15000000, maxFiles: 1, renameFile: renameFile,  headers: {'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY, 'Authorization': `Bearer ${authState?.accessToken?.accessToken}` } }));
        }
    }, [teamMemberToEdit, teamMemberIdToEdit]);

    useEffect(() => {
        if (!selectedDepartment) return;
        const getDepartmentSorting = async () => {
            apiAdmin.get(`/team-members-by-department?department=`+ selectedDepartment)
                .then((response) => {
                    setDepartmentTeam(response.data.map((a) => ( {id: a.teamMemberId, firstName: a.firstName, lastName: a.lastName})));
                    
                });

        }

        getDepartmentSorting();
    }, [selectedDepartment]);

    useEffect(() => {
        if (!selectedOffices || selectedOffices.length === 0) return;
        var officeIds = offices.filter(o => selectedOffices.includes(o.officeName)).map(o => o.officeId).join(',')
        const getOfficeSorting = async () => {
            apiAdmin.get(`/office-team-members?officeIds=`+ officeIds)
                .then((response) => {
                    setOfficeTeam(response.data.map((a) => ( {id: a.teamMemberId, firstName: a.firstName, lastName: a.lastName, officePositions : a.officePositions})));
                    
                });

        }

        getOfficeSorting();
    }, [selectedOffices]);

    const renameFile = file => {
        if (!teamMemberToEdit) return file.name;
        return (teamMemberToEdit.firstName + '-' + teamMemberToEdit.lastName + '.png').replaceAll(' ', '-').toLowerCase();
    };

    const renderSelectGroup = region => {
        const items = region.offices.map(o => {
            return (
                <MenuItem key={'office_' + o.officeId} value={o.officeName}>
                    {o.officeName}
                </MenuItem>
            );
        });
        return [<ListSubheader>{region.regionName}</ListSubheader>, items];
    };

    const handleDepartmentRowOrderChange = (e) => {
        var obj = {
            oldIndex: e.oldIndex + 1,
            targetIndex: e.targetIndex + 1,
            teamMemberId: e.row.id,
            department: selectedDepartment
        }
        const resortDepartment = async () => {
            await apiAdmin.post(`save-team-member-department-sort`, obj)
                .then((response) => {
                    if (response.statusText !== "OK") {
                        setError("There was an error resorting the team members in this department. Please try again.")
                    }
                });
        }
        resortDepartment();
    }

    const handleOfficeRowOrderChange = (e) => {
        var obj = {
            oldIndex: e.oldIndex + 1,
            targetIndex: e.targetIndex + 1,
            teamMemberId: e.row.id,
            officeId: e.row.officeId
        }
        const resortOffice = async () => {
            await apiAdmin.post(`save-team-member-office-sort`, obj)
                .then((response) => {
                    if (response.statusText !== "OK") {
                        setError("There was an error resorting the team members in this office. Please try again.")
                    }
                });
        }
        resortOffice();
    }

    return (
        <div>
            <Helmet>
                <title>Team Members</title>
            </Helmet>
            <h1>Team Members</h1>
            
            {teamMemberToEdit || teamMemberIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" defaultValue={true} id="listed" {...register("listed")} />
                                <label className="form-check-label" htmlFor="listed">Listed?</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <TextField
                                      label="First Name *"
                                      {...register("firstName", {required: true})}
                                      className="w-100"
                                    />
                                    {errors?.firstName?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                                <div className="col">
                                    <TextField
                                      label="Last Name *"
                                      {...register("lastName", {required: true})}
                                      className="w-100"
                                    />
                                    {errors?.lastName?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Name Title (i.e. LPO, CPA)"
                              {...register("nameTitle")}
                              className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Job Title"
                              {...register("title")}
                              className="w-100"
                            />
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Email *"
                              {...register("email", {required: true})}
                              className="w-100"
                            />
                            {errors?.email?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                              label="Direct Line"
                              {...register("directLine")}
                              className="w-100"
                              onChange={(e) => handlePhoneInput(e)} 
                              value={phoneInputValue} 
                            />
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        select
                                        label="Department"
                                        value={selectedDepartment}
                                        className="w-100"
                                        onChange={(e) => setSelectedDepartment(e.target.value)}
                                    >
                                      {departments.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                </div>
                                <div className="col">
                                    <span className="btn btn-link" data-bs-toggle="modal" data-bs-target="#departmentPlacementModal">Department Sorting</span>
                                </div>
                            </div>
                        </div>
                        {selectedDepartment && selectedDepartment === 'Escrow' && <div className="mb-3">
                            <FormControl fullWidth>
                                <InputLabel id="multiple-ea-label">Escrow Officer (if EA)</InputLabel>
                                <Select
                                    labelId="multiple-ea-label"
                                    id="multiple-ea"
                                    multiple
                                    value={selectedEOs}
                                    onChange={handleEOChange}
                                    input={<OutlinedInput label="Escrow Officer (if EA)" />}
                                    MenuProps={MenuProps}
                                >
                                    {teamMembers.filter(r => r.title.includes('Escrow Officer')).map((t) => {
                                        return <MenuItem key={'eo_' + t.id} value={t.id}>
                                            {t.firstName} {t.lastName}
                                        </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>}
                         <div className="modal" tabIndex="-1" id="departmentPlacementModal" >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Department Sorting</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <DataGridPro
                                            rows={departmentTeam}
                                            columns={teamColumns}
                                            loading={departmentTeam.length === 0}
                                            slotProps={{
                                                loadingOverlay: {
                                                    variant: 'skeleton',
                                                    noRowsVariant: 'skeleton',
                                                },
                                            }}
                                            autoHeight
                                            rowHeight={38}
                                            disableRowSelectionOnClick
                                            alignItems="center"
                                            rowReordering
                                            onRowOrderChange={handleDepartmentRowOrderChange}
                                            getRowClassName={(params) => (params.row.id) === teamMemberIdToEdit ? 'bg-secondary text-white' : ''}
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-6">
                                      <FormControl fullWidth>
                                        <InputLabel id="multiple-office-label">Office(s)</InputLabel>
                                        <Select
                                          labelId="multiple-office-label"
                                          id="multiple-office"
                                          multiple
                                          value={selectedOffices}
                                          onChange={handleOfficeChange}
                                          input={<OutlinedInput label="Office(s)" />}
                                          MenuProps={MenuProps}
                                        >
                                            {regions.filter(r => r.offices && r.offices.length > 0).map((region) => renderSelectGroup(region))}
                                        </Select>
                                      </FormControl>
                                </div>
                                <div className="col">
                                    <span className="btn btn-link" data-bs-toggle="modal" data-bs-target="#officePlacementModal">Office Sorting</span>
                                </div>
                            </div>
                        </div>
                        {selectedOffices && selectedOffices.length > 1 && <div className="mb-3">
                        <div className="row">
                            <div className="col">
                                <TextField
                                    select
                                    label="Primary Office"
                                    value={primaryOffice}
                                    className="w-100"
                                    onChange={(e) => setPrimaryOffice(e.target.value)}
                                >
                                    {offices.filter(o => selectedOffices.includes(o.officeName)).map((option) => (
                                        <MenuItem key={"po_" + option.officeId} value={option.officeId}>
                                            {option.officeName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                </div>
                            </div>
                        </div>}
                        <div className="modal" tabIndex="-1" id="officePlacementModal" >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Offices</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {selectedOffices && offices && offices.filter(o => selectedOffices.includes(o.officeName)).map(o => {
                                            var team = officeTeam.filter(f => f.officePositions.map(o => o.officeId).includes(o.officeId)).map(t => ({firstName: t.firstName, lastName: t.lastName, id: t.id, officeId: o.officeId, position: t.officePositions.filter(t => t.officeId === o.officeId)[0].officeListingPosition})).sort(function(a, b) {return a.position - b.position})
                                            return <div key={"o_" + o.officeId} className="mb-3">
                                                <h2>{o.officeName}</h2>

                                                <DataGridPro
                                                    rows={team}
                                                    columns={teamColumns}
                                                    loading={officeTeam.length === 0}
                                                    slotProps={{
                                                        loadingOverlay: {
                                                            variant: 'skeleton',
                                                            noRowsVariant: 'skeleton',
                                                        },
                                                    }}
                                                    rowHeight={38}
                                                    disableRowSelectionOnClick
                                                    alignItems="center"
                                                    rowReordering
                                                    onRowOrderChange={handleOfficeRowOrderChange}
                                                    getRowClassName={(params) => (params.row.id) === teamMemberIdToEdit ? 'bg-secondary text-white' : ''}
                                                />
                                            </div>
                                        })}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">                            
                            <TextField
                                id="outlined-multiline-static"
                                label="Bio"
                                multiline
                                rows={4}
                                {...register("text")}
                                className="w-100"
                                helperText="Insert ** where you would like bullet points. Insert [yearsSince(2000)] where you would like the number of years auto-calculated."
                            />
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="useHeadshotInSignature" {...register("useHeadshotInSignature")} />
                                <label className="form-check-label" htmlFor="useHeadshotInSignature">Use Headshot Photo in Signature?</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    {teamMemberToEdit && teamMemberToEdit.image && <img src={FilePath('/teamimages/' + teamMemberToEdit.image.replace('.jpg', '.png').toLowerCase()) + '?i=' + DateTime.now().second} className="m-auto d-block w-50" alt='Team Member'/> }
                                </div>
                                <div className="col">
                                    <OutlinedDiv label="Image">
                                        <div id="image" className="w-100 dropzone dropzone-previews border-0">
                                            <div className="dz-message" data-dz-message><span>Upload new headshot</span></div>
                                        </div>
                                    </OutlinedDiv>
                                </div>
                            </div>        
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <Controller
                                        name="anniversaryDate"
                                        control={control}
                                        rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("anniversaryDate")).isValid}}}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker value={value} onChange={onChange} className="w-100" label="Anniversary Date"/>
                                        )}
                                    />
                                    {errors?.anniversaryDate?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                                </div>
                                <div className="col">
                                    <Controller
                                        name="birthday"
                                        control={control}
                                        rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("birthday")).isValid}}}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker value={value} onChange={onChange} className="w-100" views={['month', 'day']} label="Birthday" />
                                        )}
                                    />
                                    {errors?.birthday?.type === "dateValid" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setTeamMemberIdToEdit(null); setTeamMemberToEdit(null); setSubmitted(false); setError(null); reset(); setSelectedDepartment(null); setSelectedOffices([]); setOfficeTeam([]); setSelectedEOs([]); setPrimaryOffice(null); setPhoneInputValue('') } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Team Member saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Team Member deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={teamMembers}
                    columns={columns}
                    loading={teamMembers.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                        toolbar: {
                            quickFilterProps: {
                                onKeyDown: blurOnEnterKey
                            }
                        }
                    }}
                    rowHeight={38}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}
                    pagination
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Team Member?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this team member?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteTeamMember()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
    );
}

export default TeamMembers;

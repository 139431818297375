/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd} from 'react-icons/md';
import { useForm, Controller } from "react-hook-form";
import { TextField, MenuItem, ToggleButtonGroup, ToggleButton, Autocomplete, createFilterOptions, Paper } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {DateTime} from 'luxon';
import ForeclosureSaleActivities from './foreclosure-sale-activities';
import { blurOnEnterKey } from '../../services/utilities';

const ForeclosureSales = (props) => {
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control  } = useForm({
    defaultValues: {
      address: '',
      city: '',
      state: '',
      zip: ''

    }
  });
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2, formState: { errors: errors2 } } = useForm();

    const [foreclosureSales, setForeclosureSales] = useState([]);
    const [foreclosureSaleIdToEdit, setForeclosureSaleIdToEdit] = useState(null);
    const [foreclosureSaleToEdit, setForeclosureSaleToEdit] = useState(null);
    const [foreclosureSaleIdToDelete, setForeclosureSaleIdToDelete] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [expandedRowIds, setExpandedRowIds] = useState([]);
    const [activityDeleted, setActivityDeleted] = useState(false);
    const [foreclosureSaleActivityIdToDelete, setForeclosureSaleActivityIdToDelete] = useState(null);

    const [error, setError] = useState(null);
    const [locations, setLocations] = useState([]);
    const [counties, setCounties] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState('Ada');
    const [saleType, setSaleType] = useState('Residential');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const filter = createFilterOptions();
    const addressSorter = (v1, v2) => (v1.address > v2.address ? 1 : -1 );
    const locationSorter = (v1, v2) => (v1.companyName > v2.companyName ? 1 : -1 );

    const columns = [
        { field: 'status', headerName: 'Status', flex: 100 },        
        { field: 'tsNumber', headerName: 'Trustee Sale Number', flex: 200 },

        { field: 'addressObject', headerName: 'Address', flex: 300, sortComparator: addressSorter, renderCell: function (t) { if (!t.value) return <></>; return <p>{t.value.address}<br/><small>{t.value.city}, {t.value.state} {t.value.zip}<br/>County: {t.value.county }</small></p> } },
        { field: 'foreclosureSaleLocation', headerName: 'Sale Location', sortComparator: locationSorter, flex: 250, renderCell: function (t) { if (!t.value) return <></>; return <p>{t.value.companyName}<br /><small>{t.value.address}<br />{t.value.city}, {t.value.state} {t.value.zip}</small></p> } },

        { field: 'currentSaleDate', headerName: 'Current Sale Date', flex: 200, renderCell: function (t) { if (!t.value) return <></>; return t.value.toLocaleString("en-US").replace(':00 ', ' ')} },
        { field: 'openingBid', headerName: 'Opening Bid', flex: 300 },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit  label="Edit"/>}
                        label="Edit"
                        onClick={() => { setForeclosureSaleIdToEdit(id)}}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete label="Delete"/>}
                        label="Delete"
                        onClick={() => { setForeclosureSaleIdToDelete(id); setDeleted(false)  }}
                        color="inherit"
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteModal" role="button" 
                    />,
                ];
            }
        }
    ];

    useEffect(() => {
        const getForeclosureSales = async () => {
            apiAdmin.get(`/foreclosure-sales`)
                .then((response) => {
                    setForeclosureSales(response.data.map((a) => ({ id: a.foreclosureSaleId, tsNumber: a.tsNumber, foreclosureSaleActivities: a.foreclosureSaleActivities, status: a.currentStatus, addressObject: {address: a.address, city: a.city, state: a.state, zip: a.zip, county: a.county}, foreclosureSaleLocation: a.foreclosureSaleLocation, currentSaleDate: new Date(a.currentSaleDate), openingBid: a.openingBid })));
                });
        }

        getForeclosureSales();

        const getCounties = async () => {
            apiAdmin.get(`/foreclosure-sale-counties`)
                .then((response) => {
                    setCounties(response.data);
                });
        }
        getCounties();
        getLocations();


    }, [submitted, deleted]);

    const getLocations = async () => {
        apiAdmin.get(`/foreclosure-sale-locations`)
            .then((response) => {
                setLocations(response.data);
            });
    }
    

    useEffect(() => {
        if (!foreclosureSaleIdToEdit || foreclosureSaleIdToEdit === -1) return;
        const getForeclosureSale = async () => {
            apiAdmin.get(`/foreclosure-sale?foreclosureSaleId=${foreclosureSaleIdToEdit}`)
                .then((response) => {
                    setForeclosureSaleToEdit(response.data);
                    setValue('originalSaleDate', DateTime.fromISO(response.data.originalSaleDate));
                    setValue('endDateTime', DateTime.fromISO(response.data.endDateTime));
                    Object.keys(response.data).forEach(function (key, index) {
                        if (key.includes('Date')) {
                            setValue(key, DateTime.fromISO(response.data[key]));
                        } else {
                            setValue(key, response.data[key]);
                        }
                        
                    });
                    setSelectedLocation(locations.find(f => f.foreclosureSaleLocationId === response.data.foreclosureSaleLocationId))
                    setSelectedCounty(response.data.county)
                    setSaleType(response.data.resCom)
                });
        }
        getForeclosureSale();
    }, [foreclosureSaleIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setForeclosureSaleIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add Foreclosure Sale
                </span>
                <GridToolbarQuickFilter  {...props.quickFilterProps}/>
            </GridToolbarContainer>
        );
    }

    const onSubmit = data => {
        const foreclosureSaleObject = {
            ...data,
            foreclosureSaleId: foreclosureSaleIdToEdit,
            foreclosureSaleLocationId: selectedLocation.foreclosureSaleLocationId,
            resCom: saleType,
            county: selectedCounty
        }
        const saveForeclosureSale = async () => {
            await apiAdmin.post(`/save-foreclosure-sale`, foreclosureSaleObject)
                .then((response) => {
                    if (response.data) {
                        setForeclosureSaleIdToEdit(null);
                        setForeclosureSaleToEdit(null);
                        setSelectedCounty(null); 
                        setSelectedLocation(null);
                        setSubmitted(true);
                        reset();
                    } else { 
                        setError("There was an error saving the foreclosure sale. Please try again.")
                    }
                });
        }
        saveForeclosureSale();
    }

    const deleteForeclosureSale = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-foreclosure-sale?foreclosureSaleId=`+ foreclosureSaleIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setForeclosureSaleIdToDelete(null);
                        setDeleted(true);
                    } else { 
                        setError("There was an error deleting the foreclosure sale. Please try again.")
                    }
                });
        }
        deleteMessage();
    }
    
    const deleteForeclosureSaleActivity = () => {
        const deleteMessage = async () => {
            await apiAdmin.post(`/delete-foreclosure-sale-activity?foreclosureSaleActivityId=`+ foreclosureSaleActivityIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setForeclosureSaleActivityIdToDelete(null);
                        setActivityDeleted(true);
                    } else { 
                        setError("There was an error deleting the foreclosure sale activity. Please try again.")
                    }
                });
        }
        deleteMessage();
    }

    const autoFillAddress = () => {
        var fileNumber = getValues('tsNumber');
        if (fileNumber === '') return;
        const autofill = async () => {
            await apiAdmin.get(`/get-address-by-file-number?fileNumber=`+ fileNumber)
                .then((response) => {
                    if (!response.data) return;
                    setValue('address', `${response.data.streetNumber} ${response.data.streetDirection ? response.data.streetDirection + ' ' : ''}${response.data.streetName} ${response.data.streetSuffix}`);
                    setValue('city', response.data.city.name);
                    setValue('state', response.data.state);
                    setValue('zip', response.data.zip)
                    setSelectedCounty(counties.find(c => c.countyId === response.data.countyId).countyName)
                });
        }
        autofill();
    }
    function DetailPanelContent({ row: rowProp }) {
        return (
            <Paper className="p-3">
                <ForeclosureSaleActivities foreclosureSaleId={rowProp.id} setForeclosureSaleActivityIdToDelete={setForeclosureSaleActivityIdToDelete} setActivityDeleted={setActivityDeleted} activityDeleted={activityDeleted} />
            </Paper>
        )    
    }

    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,);
      }, []);

    const addLocation = data => {
        if (!data) return;
        const add = async () => {
            await apiAdmin.post(`/add-foreclosure-sale-location`, data)
                .then((response) => {
                    if (response.data) {
                        reset2();
                        getLocations();
                    } else { 
                        setError("There was an error saving the foreclosure sale. Please try again.")
                    }
                });
        }
        add();
    }

    const getApplyQuickFilterFnAddress = (value) => {
        if (!value) return null;
        value = value.toLowerCase();
        return (cellValue) => {
            if (!cellValue) return false;
            return cellValue.address?.toLowerCase().split(' ').includes(value) || cellValue.city?.toLowerCase().includes(value) || cellValue.state?.toLowerCase().includes(value) || cellValue.zip?.toLowerCase().includes(value) || cellValue.county?.toLowerCase().includes(value);
            
        };
    };

    const getApplyQuickFilterFnLocation = (value) => {
        if (!value) return null;
        value = value.toLowerCase();
        return (cellValue) => {
            if (!cellValue) return false;
            return cellValue.address?.toLowerCase().split(' ').includes(value) || cellValue.city?.toLowerCase().includes(value) || cellValue.state?.toLowerCase().includes(value) || cellValue.zip?.toLowerCase().includes(value) || cellValue.companyName?.toLowerCase().includes(value);
            
        };
    };
    
    return (
        <div>
            <Helmet>
                <title>Foreclosure Sales</title>
            </Helmet>
            <h1>Foreclosure Sales</h1>
            
            {foreclosureSaleToEdit || foreclosureSaleIdToEdit === -1 ? <>
                <form key={1} onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                        <div className="row">
                            <div className="col">
                                <ToggleButtonGroup
                                    className="float-end"
                                    value={saleType}
                                    exclusive
                                    onChange={(e, newType) => {setSaleType(newType)}}
                                    aria-label="Sale Type"
                                    >
                                        <ToggleButton value="Residential" aria-label="Residential">Residential</ToggleButton>
                                        <ToggleButton value="Commercial" aria-label="Commercial">Commercial</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                        </div>
                        <hr/>
                        {foreclosureSaleIdToEdit !== -1 && <div className="mb-3">
                            <Controller
                                name="originalSaleDate"
                                control={control}
                                rules={{ validate: { dateValid: () => DateTime.fromISO(getValues("originalSaleDate")).isValid } }}
                                render={({ field: { onChange, value } }) => (
                                    <DateTimePicker disabled value={value} onChange={onChange} className="w-100" label="Original Sale Date/Time" />
                                )}
                            />
                            {errors?.originalSaleDate?.type === "dateTimeValid" && <small className="text-danger">This field is required</small>}
                        </div>}
                        <div className="mb-3">
                            <Controller
                                name="currentSaleDate"
                                control={control}
                                rules={ { validate: { dateValid: () =>  DateTime.fromISO(getValues("currentSaleDate")).isValid}}}
                                render={({ field: { onChange, value } }) => (
                                    <DateTimePicker  value={value} onChange={onChange} className="w-100" label="Sale Date/Time"/>
                                )}
                            />
                            {errors?.currentSaleDate?.type === "dateTimeValid" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                        <div className="row">
                            <div className="col col-md-9">
                                <TextField
                                    label="TS Number"
                                    {...register("tsNumber")}
                                    className="w-100"
                                />
                            </div>
                            <div className="col col-md-3">
                                <button type="button" className="btn btn-primary" onClick={() => autoFillAddress()}>Autofill Address from ResWare</button>
                            </div>
                        </div>
                            
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <Controller
                                        name="address"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({field})=>(
                                        <TextField
                                            {...field}
                                            label="Property Address *"
                                            className="w-100"
                                        />
                                    )} />
                                    {errors?.address?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-5">
                                    <Controller
                                        name="city"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({field})=>(
                                        <TextField
                                            {...field}
                                            label="City *"
                                            className="w-100"
                                        />
                                    )} />
                                    {errors?.city?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>                                   
                                <div className="col-3">
                                    <Controller
                                        name="state"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({field})=>(
                                        <TextField
                                            {...field}
                                            label="State *"
                                            className="w-100"
                                            inputProps={{maxLength: 2} }
                                        />
                                    )} />
                                    {errors?.state?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>                                
                                <div className="col-4">
                                    <Controller
                                        name="zip"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({field})=>(
                                        <TextField
                                            {...field}
                                            label="Zip *"
                                            className="w-100"
                                            inputProps={{maxLength: 5} }
                                        />
                                    )} />
                                    {errors?.zip?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                                select
                                label="County *"
                                value={selectedCounty}
                                className="w-100"
                                onChange={(e) => setSelectedCounty(e.target.value)}
                            >
                                {counties.map((option) => (
                                    <MenuItem key={"c_" + option.countyId} value={option.countyName}>
                                        {option.countyName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 col-md-9">
                                <Autocomplete
                                value={selectedLocation}
                                isOptionEqualToValue={(option, value) => {return option.foreclosureSaleId === value.foreclosureSaleId} }
                                disablePortal
                                id="sale location"
                                onChange={(event, newValue) => {
                                    setTimeout(() => {
                                        setSelectedLocation(newValue);
                                  })
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  return filtered;
                                }}
                                options={locations}
                                getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return `${option.companyName}, ${option.address} ${option.city}, ${option.state} ${option.zip}`;
                                }}
                                renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={key} {...optionProps}>
                                        {option.companyName}, {option.address} {option.city}, {option.state} {option.zip}
                                    </li>
                                );
                                }}                      
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                renderInput={(params) => <TextField {...params} label="Sale Location" />}
                            />
                            </div>
                            <div className="col-12 col-md-3">
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addLocationModal">Add New Location</button>
                            </div>
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Name/Reference"
                                {...register("name")}
                                className="w-100"
                            />
                        </div>                        
                        <div className="mb-3">
                            <TextField
                                label="Opening Bid"
                                {...register("openingBid")}
                                className="w-100"
                            />
                        </div>                        
                        <div className="mb-3">
                            <TextField
                                label="Sale Amount"
                                {...register("saleAmount")}
                                className="w-100"
                                type="number"
                                inputProps={{
                                        step: ".01"
                                      }}                            
                                />
                        </div>                        
                        <div className="mb-3">
                            <TextField
                                label="Note"
                                {...register("note")}
                                className="w-100"
                                multiline
                                rows={3}
                            />
                        </div>
                    </div>
                    <div className="pt-2">
                        <button key={1} type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setForeclosureSaleIdToEdit(null); setForeclosureSaleToEdit(null); setSelectedCounty(null); setSelectedLocation(null); setSubmitted(false); setError(null); reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                {submitted && <><div className="alert alert-success alert-dismissible" role="alert">Foreclosure Sale saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div></>}
                {deleted &&  <div className="alert alert-warning alert-dismissible" role="alert">Foreclosure Sale deleted successfully!<button type="button" className="btn-close" onClick={() => setDeleted(false)} aria-label="Close"></button></div>}
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <DataGridPro
                    rows={foreclosureSales}
                    columns={columns.map((column) => {
                      if (column.field === 'addressObject') {
                        return {
                          ...column,
                          getApplyQuickFilterFn: getApplyQuickFilterFnAddress,
                        };
                      }
                      if (column.field === 'foreclosureSaleLocation') {
                        return {
                          ...column,
                          getApplyQuickFilterFn: getApplyQuickFilterFnLocation,
                        };
                      }
                      return column;
                    })}
                    loading={foreclosureSales.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                        toolbar: {
                            quickFilterProps: {
                                onKeyDown: blurOnEnterKey
                            }
                        }
                    }}
                    autoHeight
                    rowHeight={100}
                    disableRowSelectionOnClick
                    alignItems="center"
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}
                    pagination
                    getDetailPanelContent={DetailPanelContent}
                    getDetailPanelHeight={({ row }) => 'auto'}
                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    detailPanelExpandedRowIds={expandedRowIds}
                />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Foreclosure Sale?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this foreclosure sale?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteForeclosureSale()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal shown" tabIndex="-1" id="deleteActivityModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Foreclosure Sale Activity?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this foreclosure sale activity?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteForeclosureSaleActivity()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal shown" tabIndex="-1" id="addLocationModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form key={2} onSubmit={handleSubmit2(addLocation)}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add Location?</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>Fill out the fields below to add a location</p>
                                <div className="row">
                                    <div className="col">
                                        <TextField
                                            label="Company Name *"
                                            {...register2("companyName", {required: true})}

                                            className="w-100"
                                        />
                                        {errors2?.companyName?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        <TextField
                                            label="Address *"
                                            {...register2("address", {required: true})}

                                            className="w-100"
                                        />
                                        {errors2?.address?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-5">
                                        <TextField
                                            label="City *"
                                            {...register2("city", {required: true})}

                                            className="w-100"
                                        />
                                        {errors2?.city?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>                                   
                                    <div className="col-3">
                                        <TextField
                                            label="State *"
                                            {...register2("state", {required: true})}

                                            className="w-100"
                                        />
                                        {errors2?.state?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>                                
                                    <div className="col-4">
                                         <TextField
                                            label="Zip *"
                                            {...register2("zip", { required: true })}
                                            className="w-100"
                                        />
                                        {errors2?.zip?.type === "required" && <small className="text-danger">This field is required</small>}
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button key={2} type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForeclosureSales;

import React, { useContext, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './nav-menu.scss';
import navLinks from './nav-link'
import NavIcon from './nav-icon'
import { FilePath, FormatNameToFilePath } from '../../../services/utilities';
import { AdminUserContext } from '../../../contexts/user-context';
import { useOktaAuth } from '@okta/okta-react';
import intersection from 'lodash/intersection';

const NavMenu = () => {
    const { adminUserProfile, setAdminUserProfile, isImpersonated, setIsImpersonated } = useContext(AdminUserContext);
    const { oktaAuth, authState } = useOktaAuth();
    const location = useLocation();
    const currentLocation = location.pathname;
    const logout = async () => oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/loggedout' }); 

    const stopImpersonate = () => {
        localStorage.removeItem("T1AdminImpersonate");
        setIsImpersonated(false);
        setAdminUserProfile();
    };

    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark h-100">
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <img src={FilePath('/images/logos/TOnly.png')} alt="TitleOne Logo" className="bi me-2" aria-label="TitleOne Logo" height="50px" />
                <span className="fs-4 lh-sm">TitleOne Admin</span>
            </a>
            <hr />
            {isImpersonated && <div className="text-danger text-center">IMPERSONATING</div>}
            {authState?.isAuthenticated && adminUserProfile &&
                <>
                    <ul className="nav nav-pills flex-column mb-auto">
                    {navLinks.filter(l => adminUserProfile.memberOf.includes("webdev") || intersection(l.userGroups, adminUserProfile.memberOf).length > 0).map((l,i) => {
                        return <li key={i} className="nav-item">
                            <Link to={l.route} className={currentLocation === l.route ? "nav-link active" : "nav-link"}><NavIcon route={l.route} /><span className="ps-2">{l.title}</span></Link>
                        </li>
                    })}
                    </ul>
                    <hr />
                    <div className="dropdown">
                        <a href="##" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={FilePath(`/teamimages/${FormatNameToFilePath(`${adminUserProfile.firstName} ${adminUserProfile.lastName}`)}.png`)} alt="" width="32" height="32" className="rounded-circle me-2" />
                            <strong>{adminUserProfile.displayName}</strong>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                        {isImpersonated && <li><span className="dropdown-item" role="button" onClick={stopImpersonate}>Stop Impersonating</span></li>}
                        {!isImpersonated && adminUserProfile.memberOf.includes("webdev") && <li><Link className="dropdown-item" to="/impersonate">Impersonate</Link></li>}
                            <li><hr className="dropdown-divider" /></li>
                            <li><span className="dropdown-item" role="button" onClick={logout}>Sign out</span></li>
                        </ul>
                    </div>
                </>}
        </div>
    );
}

export default NavMenu;
import axios from 'axios';

const apiAdmin = axios.create({
  baseURL: process.env.REACT_APP_API_ADMIN_BASE_URL
});

apiAdmin.interceptors.request.use(
    request => {
        var authorizeHeader = "";
        var accessToken = JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken?.accessToken;
        if (accessToken) {
            authorizeHeader = `Bearer ${accessToken}`;
        }
        request.headers['Authorization'] = authorizeHeader;

        var impersonate = localStorage.getItem("T1AdminImpersonate");
        if (impersonate) {
            request.headers['T1AdminImpersonate'] = impersonate;
        }

        request.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_API_ADMIN_SUBSCRIPTION_KEY;
        return request;
    },
    error => {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

apiAdmin.interceptors.response.use(
    response => {
        // Return a successful response back to the calling service
        return response;
    },
    error => {
        if (error.response.status !== 0 && !error.config.noErrorHandling && process.env.NODE_ENV === 'development') {
            console.log("apiAdmin Error, will redirect to 'oops' when deployed", error);
        }
        if (error.response.status !== 0 && !error.config.noErrorHandling && process.env.NODE_ENV !== 'development') {
            window.location.href = '/oops';
        }
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

export default apiAdmin;
import React, { useEffect } from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { AdminUserProvider } from './contexts/user-context';
import RequireAuth from './services/require-auth';
import config from './auth-config';

import { LicenseInfo } from '@mui/x-data-grid-pro';

import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';

import Layout from './components/layout/layout';
import ScrollToTop from './components/scroll-to-top';
import './t1bootstrap.scss'
import './app.scss'

import { lazyLoadWithRetry } from './services/lazy-load-with-retry';

import SpinnerLoader from './components/layout/spinner-loader';

import Home from './components/home/home';

import BucksOneBalances from './components/tools/bucksone-balances';
import ClothingStoreManagement from './components/tools/clothing-store-management';
import ClothingStoreProducts from './components/tools/clothing-store-products';
import Classes from './components/tools/classes';
import DigitalHomePortfolios from './components/tools/digital-home-portfolios';
import Ecards from './components/tools/ecards/ecards';
import ForeclosureSales from './components/tools/foreclosure-sales';
import GolfTournaments from './components/tools/golf-tournaments';
import HomePageCards from './components/tools/home-page-cards';
import Impersonate from './components/impersonate';
import ItemOfTheWeek from './components/tools/item-of-the-week';
import LoggedOut from './components/home/loggedout';
import MarketShare from './components/tools/market-share';
import NewsArticles from './components/tools/news-articles';
import Offices from './components/tools/offices';
import PreferredCustomers from './components/tools/preferred-customers';
import PromoItemManagement from './components/tools/promo-item-management';
import PromoOrderManagement from './components/tools/promo-order-management';
import RepsOfTheYear from './components/tools/reps-of-the-year';
import SalesToolkit from './components/tools/sales-toolkit';
import SiteMessages from './components/tools/site-messages';
import TeamMembers from './components/tools/team-members';
import PromoOrderZones from './components/tools/promo-order-zones';
import PromoOrderForm from './components/tools/promo-order-form';
import TextAPickUp from './components/tools/text-a-pick-up';

const Oops = lazyLoadWithRetry(() => import('./components/oops'));
const PageNotFound = lazyLoadWithRetry(() => import('./components/page-not-found'));

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
    var manifest = document.getElementById('manifestPlaceholder');
    manifest.setAttribute('href', 't1.webmanifest')
    
    const navigate = useNavigate();
    const restoreOriginalUri = (_oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY);

    const location = useLocation();
    useEffect(() => {

        ReactGA.initialize(process.env.REACT_APP_GACODE);
        ReactGA.send({ hitType: "pageview", page: location.pathname });

    }, [location]);

    useEffect(() => {
        window.onpopstate = e => {
            document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
            document.querySelector("body").classList.remove("modal-open");
            document.querySelector("body").removeAttribute("style");
        };

    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <AdminUserProvider>
                    <ScrollToTop>
                        <Routes>
                            <Route path='/' element={<Layout />} >
                                <Route path='/loggedout' element={<LoggedOut />} />
                                <Route path='/clothing-store-management' element={<ClothingStoreManagement />} />
                                <Route path="/oops" element={<Oops />} />
                            </Route>
                            <Route path='/login/callback' element={<LoginCallback loadingElement={<SpinnerLoader />} />} />

                            <Route element={<RequireAuth><Layout /></RequireAuth>}>
                                <Route path="*" element={<PageNotFound />} />
                                <Route index element={<Home />} />
                                <Route path='/bucksone-balances' element={<BucksOneBalances />} />
                                <Route path='/classes' element={<Classes />} />
                                <Route path='/clothing-store-products' element={<ClothingStoreProducts />} />
                                <Route path='/ecards' element={<Ecards />} />
                                <Route path='/digital-home-portfolios' element={<DigitalHomePortfolios />} />
                                <Route path='/foreclosure-sales' element={<ForeclosureSales />} />
                                <Route path='/golf-tournaments' element={<GolfTournaments />} />
                                <Route path='/home-page-cards' element={<HomePageCards />} />
                                <Route path='/impersonate' element={<Impersonate />} />
                                <Route path='/item-of-the-week' element={<ItemOfTheWeek />} />
                                <Route path='/market-share' element={<MarketShare />} />
                                <Route path='/news-articles' element={<NewsArticles />} />
                                <Route path='/offices' element={<Offices />} />
                                <Route path='/preferred-customers' element={<PreferredCustomers />}>
                                    <Route path='/preferred-customers/:paramPreferredCustomer' element={<PreferredCustomers />} />
                                </Route>
                                <Route path='/promo-item-management' element={<PromoItemManagement />} />
                                <Route path='/promo-order-form' element={<PromoOrderForm />}>
                                    <Route path='/promo-order-form/:paramPromoZone' element={<PromoOrderForm />} />
                                </Route>
                                <Route path='/promo-order-management' element={<PromoOrderManagement />} />
                                <Route path='/promo-order-zones' element={<PromoOrderZones />} />
                                <Route path='/reps-of-the-year' element={<RepsOfTheYear />} />
                                <Route path='/sales-toolkit' element={<SalesToolkit />} />
                                <Route path='/site-messages' element={<SiteMessages />} />
                                <Route path='/team-members' element={<TeamMembers />} />
                                <Route path='/text-a-pick-up' element={<TextAPickUp />} />
                            </Route>
                        </Routes>
                    </ScrollToTop>
                </AdminUserProvider>
            </Security>
        </LocalizationProvider>

    );
}

export { App, bootstrap };

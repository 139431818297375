/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbarContainer, GridActionsCellItem  } from '@mui/x-data-grid-pro';
import apiAdmin from '../../services/api-admin';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { useForm,} from "react-hook-form";
import { TextField } from '@mui/material';

const SalesToolkitEditCategories = ({categories, setCategoryDeleted, setCategorySubmitted, setCategoryError, setEditingCategories}) => {
    const { register, handleSubmit, setValue, reset, formState: { errors }  } = useForm();
    const [categoryIdToEdit, setCategoryIdToEdit] = useState(null);
    const [categoryToEdit, setCategoryToEdit] = useState(null);
    const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);

    const columns = [
        { field: 'category', headerName: 'Category', flex: 100 },
        { field: 'icon', headerName: 'Icon', flex: 100 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {

                return [
                    <GridActionsCellItem
                        icon={<MdEdit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => { setCategoryIdToEdit(id) }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<MdDelete />}
                        label="Delete"
                        onClick={() => { setCategoryIdToDelete(id); setCategoryDeleted(false) }}
                        color="inherit"
                        data-bs-toggle="modal"
                        data-bs-target={"#deleteCategoryModal"} role="button" 
                    />,
                ];
            }
        }
        ];

    useEffect(() => {
        if (!categoryIdToEdit || categoryIdToEdit === -1) return;
        const getCategory = async () => {
            apiAdmin.get(`/sales-toolkit-category?categoryId=${categoryIdToEdit}`)
                .then((response) => {
                    setCategoryToEdit(response.data);
                    setValue('category', response.data.category);
                    setValue('icon', response.data.icon);
                });
        }
        getCategory();
    }, [categoryIdToEdit]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <span className="btn-link btn" onClick={() => {setCategoryIdToEdit(-1)} }>
                    <MdAdd className="mb-1"/> Add Category
                </span>
            </GridToolbarContainer>
        );
    }
    const onSubmit = data => {

        const categoryObject = {
            ...data,
            categoryId: categoryIdToEdit
        }
        const saveCategory = async () => {
            await apiAdmin.post(`/save-sales-toolkit-category`, categoryObject)
                .then((response) => {
                    if (response.data) {
                        setCategoryIdToEdit(null);
                        setCategoryToEdit(null);
                        setCategorySubmitted(true);
                        reset();
                    } else { 
                        setCategoryError("There was an error saving the category. Please try again.")
                    }
                });
        }
        saveCategory();
    }

    const deleteCategory = () => {
        const deleteArticle = async () => {
            await apiAdmin.post(`/delete-sales-toolkit-category?categoryId=`+ categoryIdToDelete)
                .then((response) => {
                    if (response.statusText === "OK") {
                        setCategoryIdToDelete(null);
                        setCategoryDeleted(true);
                    } else { 
                        setCategoryError("There was an error deleting the category. Please try again.")
                    }
                });
        }
        deleteArticle();
    }

    const resortRows = (e) => { 
        var obj = {
            oldIndex: e.oldIndex + 1,
            targetIndex: e.targetIndex + 1,
            categoryId: e.row.id,
        }
        const resortCategories = async () => {
            await apiAdmin.post(`save-sales-toolkit-category-sort`, obj)
                .then((response) => {
                    if (response.statusText !== "OK") {
                        setCategoryError("There was an error re-sorting the categories. Please try again.")
                    } else { 
                        setCategorySubmitted(true);
                    }
                });
        }
        resortCategories();
    }
    
    return (
        <div>       
            {categoryToEdit || categoryIdToEdit === -1 ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-3">
                        <div className="mb-3">
                            <TextField
                                label="Category *"
                                {...register("category", {required: true})}
                                className="w-100"
                            />
                            {errors?.category?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="mb-3">
                            <TextField
                                label="Icon *"
                                {...register("icon", {required: true})}
                                className="w-100"
                            />
                            {errors?.category?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <p><a href="https://react-icons.github.io/react-icons/icons/md/" target="_blank" rel="noreferrer">Click here</a> to view available icons.</p>
                    </div>
                    <div className="pt-2">
                        <button type="submit" className="btn btn-primary me-4">Submit</button>
                        <button type="button" className="btn btn-primary" onClick={() => { setCategoryIdToEdit(null); setCategoryToEdit(null); setCategorySubmitted(false); setCategoryError(null); reset(); } }>Cancel</button>
                    </div>
                </form>
            </>
            :
            <>
                    <button className="btn btn-primary mb-2" onClick={() => { setCategoryIdToEdit(null); setCategoryToEdit(null); setCategorySubmitted(false); setCategoryDeleted(false); setCategoryError(null); reset(); setEditingCategories(false) }}>Back</button>
                    <p>The top half (rounded up) of categories will be on the left side of the sales toolkit. The rest will be on the right.</p>
                    <DataGridPro
                        rows={categories}
                        columns={columns}
                        loading={categories.length === 0}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                        }}
                        autoHeight
                        rowHeight={38}
                        disableRowSelectionOnClick
                        alignItems="center"
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        rowReordering
                        onRowOrderChange={(e) => resortRows(e)}
                    />
            </>
            }

            <div className="modal shown" tabIndex="-1" id="deleteCategoryModal" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Category?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this category?</p>
                            <p>Deleting a category will also delete any documents in that category.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => deleteCategory()}>Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalesToolkitEditCategories;

import React, { useContext, useRef, useState, useEffect } from 'react';
import "./home.scss"
import { Helmet } from 'react-helmet-async';
import apiAdmin from '../../services/api-admin';


const Home = () => {

    return (
        <>
            <Helmet>
                <title>TitleOne Admin</title>
                <meta name="description" content="TitleOne Admin Site" />
            </Helmet>
            
        </>
  );
}

export default Home;
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form"
import { TextField } from '@mui/material';
import apiAdmin from '../../services/api-admin';
import { DateTime } from 'luxon';
import { FormatNumber } from '../../services/utilities';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import some from 'lodash/some';

const MarketShareDataEntry = ({ formSelections, countyLoanTypeBuckets, companies}) => {
    const { handleSubmit, reset, setValue, getValues, register, formState: { errors } } = useForm();
    const [filteredCompanies, setFilteredCompanies] = useState();
    const [proofingTotal, setProofingTotal] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState();
    const [marketShareData, setMarketShareData] = useState();

    useEffect(() => {
        reset();
        setFilteredCompanies();
        setSubmitted(false);
        setError(null);
        setProofingTotal(0);
    }, [formSelections]);

    useEffect(() => {
        const getData = async () => apiAdmin.post(`/market-share/getdata`, { ...formSelections })
            .then(response => {
                setMarketShareData(response.data);
            });
        getData();
    }, [companies]);

    const enabledOrHistorical = (cpnys) => {
        return cpnys.filter(c => {
            return ((c.enabled && c.countyCompanies[0].enabled)
                || some(marketShareData, msd => msd.companyId === c.companyId));
        });
    };

    useEffect(() => {
        if (!marketShareData || !countyLoanTypeBuckets || !companies) return;
        setFilteredCompanies(enabledOrHistorical(companies));

        marketShareData.forEach((d) => {
            setValue(`${d.companyId}--${d.countyLoanTypeBucketId}`, FormatNumber(d.value));
        });
        calculateProofingTotal();
    }, [marketShareData, countyLoanTypeBuckets, setValue]);

    const LastUpdated = ({ companyId, countyLoanTypeBucketId }) => {
        var data = marketShareData.find(d => (d.companyId === companyId && d.countyLoanTypeBucketId === countyLoanTypeBucketId));
        return data && <small className="d-block">Last Updated: {DateTime.fromISO(data.changeTimeStamp).toLocaleString(DateTime.DATETIME_SHORT)}</small>;
    };

    const calculateProofingTotal = () => {
        var formValues = getValues();
        var dataValues = Object.entries(formValues).map(([ids, value], idx) => {
            return (value) ? parseFloat(value.replace(/[^\d^\.]/g, "")) : 0;
        });
        setProofingTotal(FormatNumber(sum(dataValues)));
    };

    const saveData = data => {
        var marketShareDataToSave = Object.entries(data).map(([ids, value], idx) => {
            return {
                companyId: ids.split('--')[0],
                countyLoanTypeBucketId: ids.split('--')[1],
                value: (value) ? parseFloat(value.replace(/[^\d^\.]/g, "")) : 0
            };
        });

        const saveData = async () => {
            apiAdmin.post(`/market-share/savedata`, {
                dataParameters: { ...formSelections },
                data: marketShareDataToSave
            }, { noErrorHandling: true })
                .then(response => {
                    setMarketShareData(response.data);
                })
                .catch((err) => {
                    console.log("Something went wrong", err);
                    setError(err);
                })
                .finally(() => {
                    setSubmitted(true);
                });
        };

        saveData();
    };

    return (
        <div>
            {formSelections && countyLoanTypeBuckets && companies &&
                <form onSubmit={handleSubmit(saveData)}>
                    {orderBy(filteredCompanies, c => c.countyCompanies[0].order).map((company, companyIdx) => {
                        return <div className="mb-3" key={companyIdx}>
                            <h3>{company.name} {(company.aka) && <>(aka {company.aka})</>}</h3>
                            {orderBy(countyLoanTypeBuckets, cltb => cltb.bucket.order).map((cltb, bucketIdx) => {
                                return <div key={`${companyIdx}-${bucketIdx}`} className="mb-3">
                                    <TextField key={`${company.companyId}--${cltb.countyLoanTypeBucketId}`}
                                        label={cltb.bucket.label}
                                        defaultValue={0}
                                        autoComplete="off"
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        {...register(`${company.companyId}--${cltb.countyLoanTypeBucketId}`, {
                                            pattern: /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
                                            onChange: (e) => {
                                                setValue(e.target.name, FormatNumber(e.target.value));
                                                calculateProofingTotal();
                                            }
                                        })}
                                        className="w-100"
                                    /> <LastUpdated companyId={company.companyId} countyLoanTypeBucketId={cltb.countyLoanTypeBucketId} />
                                    {errors && errors[`${company.companyId}--${cltb.countyLoanTypeBucketId}`]?.type === "pattern" && <small className="d-block text-danger">This field should be a number.</small>}
                                </div>
                            })}
                        </div>
                    })}
                    <div className="mb-3">
                        <h4>Proofing Total: {proofingTotal}</h4>
                    </div>
                    <div className="mb-3">
                        <input type="submit" value="Submit" className="btn btn-primary" aria-label="Submit" />
                    </div>
                </form>
            }
            {submitted && <div className="alert alert-success alert-dismissible" role="alert">Market Share data saved successfully!<button type="button" className="btn-close" onClick={() => setSubmitted(false)} aria-label="Close"></button></div>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}

        </div>
    );
}

export default MarketShareDataEntry;
